<template>
  <div class="welcome">
    <div class="warning">
      <a-alert
        description="Activate brands to add products to the Creator Marketplace."
        type="warning"
        show-icon
      />
      <div class="show-brand">
        <a-button @click="() => setShowActivateBrand(true)" type="primary">Activate Brand</a-button>
      </div>
    </div>
    <div class="content">
      <div class="top-cont">
        <h3>Welcome to ArtemisAds,</h3>
        <p>Our business staff will contact you later, you can also contact us through the following ways.</p>
        <h4>Contact Us</h4>
        <div class="mail">
          <img src="../../../assets/images/admin/welcome/mail.png"/>
          <a>Support@Artemisads.com</a>
        </div>
        <div class="address">
          <img src="../../../assets/images/admin/welcome/address.png"/>
          <span>740 E Campbell Rd Ste 700, Richardson, TX 75081</span>
        </div>
        <div class="qr-code">
          <img src="../../../assets/images/admin/welcome/app_downlaod.png"/>
        </div>
      </div>
      <div class="bottom-cont">
        <span>Thanks,</span>
        <span>The ArtemisAds Team</span>
      </div>
    </div>
  </div>
  <activateBrand :setShowActivateBrand="setShowActivateBrand" :showActivateBrand="showActivateBrand"/>
</template>
<script setup>

  import { ref } from 'vue';

  import activateBrand from '../activateBrand.vue';

  const showActivateBrand = ref(false);

  const setShowActivateBrand = status => {
    showActivateBrand.value = status;
  }

</script>
<style lang="less" scoped>
  .welcome {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    .warning {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .show-brand {
        position: absolute;
        bottom: 10px;
        right: 50%;
        margin-right: -270px;
      }
      :deep(.ant-alert) {
        width: 560px;
        padding-bottom: 50px;
      }
    }
    .content {
      align-self: center;
      width: 560px;
      background: #FFFFFF;
      border: 1px solid #D3CEE0;
      box-shadow: 0 1px 0 0 #1a16250d;
      border-radius: 8px;
    }
    .top-cont {
      padding: 48px 40px 40px 40px;
      > h3 {
        height: 36px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 28px;
        color: #0F0629;
        letter-spacing: 0.5px;
        line-height: 36px;
      }
      > p {
        margin-top: 8px;
        line-height: 20px;
        opacity: 0.7;
        font-family: Sora-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        padding-bottom: 31px;
        border-bottom: 1px solid #D3CEE0;
        box-shadow: 0 1px 0 0 #1a16250d;
      }
      > h4 {
        margin-top: 32px;
        height: 30px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 20px;
        color: #0F0629;
        letter-spacing: 0;
        line-height: 30px;
      }
      .address, .mail {
        display: flex;
        > img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          align-self: center;
        }
      }
      .address {
        margin-top: 12px;
        > span {
          opacity: 0.7;
          font-family: Sora-Regular;
          font-weight: 400;
          font-size: 16px;
          color: #0F0629;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      .mail {
        margin-top: 24px;
        > a {
          height: 24px;
          font-family: Sora-Regular;
          font-weight: 400;
          font-size: 16px;
          color: #0F0629;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      .qr-code {
        margin-top: 14px;
        width: 100px;
        height: 100px;
        border: 1px dashed #D3CEE0;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 4px;
      }
    }
    .bottom-cont {
      height: 64px;
      background: #F9F7FC;
      border-radius: 0 0 8px 8px;
      text-align: right;
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      padding-top: 14px;
      > span {
        opacity: 0.4;
        font-family: Sora-Regular;
        font-weight: 400;
        font-size: 13px;
        color: #0F0629;
        letter-spacing: 0.4px;
        text-align: right;
        line-height: 18px;
      }
    }
  }
</style>